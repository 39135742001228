<template>

  <b-overlay
    :show="loading"
    rounded="sm"
  >

    <b-card title="Edit monitoredItem">
      <b-form @submit.prevent>

        <b-row>

          <b-col cols="12">
            <b-form-group
              label="Item Name"
              label-for="v-itemName"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.itemName"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-itemName"
                v-model="monitoredItem.itemName"
                placeholder="Item Name"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.itemName }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">

            <b-form-group
              label="ASIN"
              label-for="v-asin"
            >
              <b-form-input
                id="v-asin"
                v-model="monitoredItem.asin"
                placeholder="ASIN"
                required
              />

            </b-form-group>
            
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="SKU"
              label-for="v-sku"
            >
              <b-form-input
                id="v-sku"
                v-model="monitoredItem.sku"
                placeholder="SKU"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Parent SKU"
              label-for="v-parentSku"
            >

            <b-form-checkbox
                  v-model="fieldsToIgnore.parentSku"
                  value="true"
                > Ignore
              </b-form-checkbox>
            
              <b-form-input
                id="v-parentSku"
                v-model="monitoredItem.parentSku"
                placeholder="SKU Group"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.parentSku }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">

            <b-form-group
              label="Parent ASIN"
              label-for="v-parentAsin"
            >

              <b-form-checkbox
                  v-model="fieldsToIgnore.parentAsin"
                  value="true"
                > Ignore
              </b-form-checkbox>

              <b-form-input
                id="v-parentAsin"
                v-model="monitoredItem.parentAsin"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.parentAsin }"
                placeholder="Parent ASIN"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Style Name"
              label-for="v-styleName"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.styleName"
                value="true"
              > Ignore
              </b-form-checkbox>

              <b-form-input
                id="v-styleName"
                v-model="monitoredItem.styleName"
                placeholder="Style Name"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.styleName }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="brand"
              label-for="v-brand"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.brand"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-brand"
                v-model="monitoredItem.brand"
                placeholder="Brand"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.brand }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="v-description"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.description"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-description"
                v-model="monitoredItem.description"
                placeholder="Description"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.description }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="bulletPoint1"
              label-for="v-bulletPoint1"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.bulletPoint1"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-bulletPoint1"
                v-model="monitoredItem.bulletPoint1"
                placeholder="bulletPoint1"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.bulletPoint1 }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="bulletPoint2"
              label-for="v-bulletPoint2"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.bulletPoint2"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-bulletPoint2"
                v-model="monitoredItem.bulletPoint2"
                placeholder="bulletPoint2"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.bulletPoint2 }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="bulletPoint3"
              label-for="v-bulletPoint3"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.bulletPoint3"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-bulletPoint3"
                v-model="monitoredItem.bulletPoint3"
                placeholder="bulletPoint3"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.bulletPoint3 }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="bulletPoint4"
              label-for="v-bulletPoint4"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.bulletPoint4"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-bulletPoint4"
                v-model="monitoredItem.bulletPoint4"
                placeholder="bulletPoint4"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.bulletPoint4 }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="bulletPoint5"
              label-for="v-bulletPoint5"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.bulletPoint5"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-bulletPoint5"
                v-model="monitoredItem.bulletPoint5"
                placeholder="bulletPoint5"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.bulletPoint5 }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="maxOrderQuantity"
              label-for="v-maxOrderQuantity"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.maxOrderQuantity"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-maxOrderQuantity"
                v-model="monitoredItem.maxOrderQuantity"
                placeholder="maxOrderQuantity"
                type="number"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.maxOrderQuantity }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="variationTheme"
              label-for="v-variationTheme"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.variationTheme"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-variationTheme"
                v-model="monitoredItem.variationTheme"
                placeholder="variationTheme"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.variationTheme }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="itemType"
              label-for="v-itemType"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.itemType"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-itemType"
                v-model="monitoredItem.itemType"
                placeholder="itemType"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.itemType }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="supplierDeclaredDGHZRegulation"
              label-for="v-supplierDeclaredDGHZRegulation"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.supplierDeclaredDGHZRegulation"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-supplierDeclaredDGHZRegulation"
                v-model="monitoredItem.supplierDeclaredDGHZRegulation"
                placeholder="supplierDeclaredDGHZRegulation"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.supplierDeclaredDGHZRegulation }"

                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="manufacturer"
              label-for="v-manufacturer"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.manufacturer"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-manufacturer"
                v-model="monitoredItem.manufacturer"
                placeholder="manufacturer"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.manufacturer }"

                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="unitCount"
              label-for="v-unitCount"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.unitCount"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-unitCount"
                v-model="monitoredItem.unitCount"
                placeholder="unitCount"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.unitCount }"

                type="number"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="pPUCountType"
              label-for="v-pPUCountType"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.pPUCountType"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-pPUCountType"
                v-model="monitoredItem.pPUCountType"
                placeholder="pPUCountType"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.pPUCountType }"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="isHeatSensitive"
              label-for="v-isHeatSensitive"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.isHeatSensitive"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-select
                v-model="monitoredItem.isHeatSensitive"

                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.isHeatSensitive }"
                :options="BooleanOptions"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="areBatteriesRequired"
              label-for="v-areBatteriesRequired"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.areBatteriesRequired"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-select
                v-model="monitoredItem.areBatteriesRequired"
                :options="BooleanOptions"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.areBatteriesRequired }"
              />

            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="conditionType"
              label-for="v-conditionType"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.conditionType"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-conditionType"
                v-model="monitoredItem.conditionType"
                placeholder="conditionType"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="isExpirationDatedProduct"
              label-for="v-isExpirationDatedProduct"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.isExpirationDatedProduct"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-select
                v-model="monitoredItem.isExpirationDatedProduct"
                :options="BooleanOptions"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.isExpirationDatedProduct }"
              />

            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="mfrPartNumber"
              label-for="v-mfrPartNumber"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.mfrPartNumber"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-mfrPartNumber"
                v-model="monitoredItem.mfrPartNumber"
                placeholder="mfrPartNumber"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.mfrPartNumber }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="SearchTerms"
              label-for="v-SearchTerms"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.searchTerms"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-SearchTerms"
                v-model="monitoredItem.searchTerms"
                placeholder="SearchTerms"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.searchTerms }"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="productTypeNodeParent"
              label-for="v-productTypeNodeParent"
            >
              <br>
              <b-form-input
                id="v-productTypeNodeParent"
                v-model="monitoredItem.productTypeNodeParent"
                placeholder="Ex: Health"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="productType"
              label-for="v-productType"
            >
              <b-form-checkbox
                v-model="fieldsToIgnore.productType"
                value="true"
              > Ignore
              </b-form-checkbox>
              <b-form-input
                id="v-productType"
                v-model="monitoredItem.productType"
                placeholder="Ex: Massager"
                :class="{'bg-secondary bg-gradient text-white': fieldsToIgnore.productType }"
                required
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="loading"
              @click="saveItem"
            >
              Save
            </b-button>

            <router-link :to="{ name : 'amz-selling-partners-monitored', params: {accountId:monitoredItem.accountId} }">

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Back
              </b-button>

            </router-link>

          </b-col>
        </b-row>

      </b-form>

    </b-card>

  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BForm, BButton, BFormRadioGroup,
  BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BOverlay,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      monitoredItem: {},
      fieldsToIgnore: {
      },
      BooleanOptions: [
        { value: null, text: 'Please select an option' },
        { value: true, text: 'True' },
        { value: false, text: 'False' },
      ],
      channelSelected: null,
      accountId: null,
    }
  },
  mounted() {
    this.itemId = this.$route.params.itemId
    this.loadItem(this.itemId)
  },
  methods: {

    loadItem(itemId) {
      this.$store.dispatch('amzSellingPartnerAccount/getMonitoredItem', itemId)
        .then(result => {
          this.monitoredItem = result.data
          for (const item of result.data.fieldsToIgnore) {
            this.fieldsToIgnore[item] = true
          }
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
        })
    },

    saveItem() {
      // this.loading = true
      this.monitoredItem.fieldsToIgnore = []
      const parsedobj = JSON.parse(JSON.stringify(this.fieldsToIgnore))
      const fieldsToIgnore = []
      for (const [key, value] of Object.entries(parsedobj)) {
        if (value === 'true' || value === true) {
          fieldsToIgnore.push(key)
        }
      }

      this.monitoredItem.fieldsToIgnore = fieldsToIgnore

      this.$store.dispatch('amzSellingPartnerAccount/updateMonitoredItem', this.monitoredItem)
        .then(result => {
          this.showToast('Success', 'Item was updated successfully', 'success')
          // this.$router.push({ name : 'amz-selling-partners-monitored', params:{accountId: this.accountId} });
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while saving', 'danger')
        }).finally(() => {
          this.loading = false
        })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
